import PropTypes from 'prop-types'

import './index.scss'

function Etiquette({ title, filterBlogHandling }) {
	return (
		<div className="SOUKIASSIAN-Etiquette" onClick={filterBlogHandling}>
			{title}
		</div>
	)
}

Etiquette.propTypes = {
	title: PropTypes.any,
	filterBlogHandling: PropTypes.func,
}

Etiquette.defaultProps = {
	filterBlogHandling: () => {},
}

export default Etiquette
