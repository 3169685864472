import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLoaderData, useOutlet } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getBlog, getBlogFilters, getBlogs } from '../../actions/blog.action'
import {
	filterBlogByTypeId,
	filterBlogType,
	getImageFromBlogList,
	getTextFromBlogList,
} from '../../utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import BannerText from '../../components/BannerText'
import Etiquette from '../../components/Etiquette'
import EtiquetteList from '../../components/Etiquette/List'
import BlogEtiquette from '../../components/BlogEtiquette'
import BlogEtiquetteList from '../../components/BlogEtiquette/List'
import Button from '../../components/Button'
import LineDividing from '../../components/LineDividing'

import './index.scss'

function Blog({ isIdBlog }) {
	const [blogFilterId, setBlogFilterId] = useState(-1)
	const blogList = useSelector((state) => state.blogReducer.list)
	const blogFilterList = useSelector((state) => state.blogReducer.listFilter)
	const outlet = useOutlet()
	const data = useLoaderData()
	const pageId = 31
	const blogId = data?.id
	const dispatch = useDispatch()
	const refBlogList = useRef()

	const blogTexts = getTextFromBlogList(blogList, blogId)
	const blogImage = getImageFromBlogList(blogList, blogId)
	const blogFiltersArticle = filterBlogType(blogFilterList, 'Article')

	useEffect(() => {
		if (data != null && data.id != null) {
			dispatch(getBlog(data.id))
		}
		dispatch(getBlogs())
		dispatch(getBlogFilters())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const filterBlogHandling = function (id) {
		setBlogFilterId(id)
		if (refBlogList.current) {
			const blogListIsVisible =
				window.scrollY + window.innerHeight >
				refBlogList.current.offsetTop + 200

			if (!blogListIsVisible) {
				window.scrollTo(0, refBlogList.current.offsetTop)
			}
		}
	}

	return (
		<>
			{outlet || (
				<div className="SOUKIASSIAN-Blog">
					{!isIdBlog ? (
						<>
							<Header
								type="blogHome"
								title="ARTICLES INSPIRANTS"
								subTitle="“Découvrez nos articles et renforcez la connaissance de vous-même.”"
								description={
									<>
										Venez vivre vos réflexions inspirantes,
										avec des clés pour cultiver une vie plus
										harmonieuse et authentique.
									</>
								}
								pageId={pageId}
								entityId={1}
							/>
							<BannerText textNoBg>
								Cet espace est dédié à l’évolution de l'être
								humain
								<br />
								<span className="hr"></span>
								<br />
								-Les sept piliers de l'école-
							</BannerText>
							<EtiquetteList>
								<Etiquette
									key={`blog_filter_0`}
									title="Explorez tous les Articles"
									filterBlogHandling={() => {
										filterBlogHandling(0)
									}}
								/>
								{blogFiltersArticle?.map((articleFilter) => (
									<Etiquette
										key={`blog_filter_${articleFilter.id}`}
										title={articleFilter.name}
										filterBlogHandling={() => {
											filterBlogHandling(articleFilter.id)
										}}
									/>
								))}
							</EtiquetteList>
							<div ref={refBlogList}>
								<LineDividing />
								<BlogEtiquetteList>
									{filterBlogByTypeId(
										blogList,
										blogFilterId,
									)?.map((blog) => (
										<BlogEtiquette
											key={`blog_${blog.id}`}
											type="blogHome"
											title={blog.title}
											pageId={blog.image.pageId}
											entityId={blog.image.entityId}
											blogId={blog.id}
										/>
									))}
								</BlogEtiquetteList>
							</div>
						</>
					) : (
						<>
							<Header
								type="blogPage"
								title={blogTexts?.title}
								pageId={blogImage?.pageId}
								entityId={blogImage?.entityId}
							/>
							<div className="col-max-blocked center margined-y">
								<Button link={'/blog'}>
									<FontAwesomeIcon icon={faReply} />
									{' REVENIR AUX ACTUALITÉS'}
								</Button>
							</div>
							<section className="SOUKIASSIAN-Blog-section col-max-blocked center">
								<div>
									<h2>
										Communiquer sans blesser et sans être
										blessé avec la méthode E.E.N.B. : L’Art
										d’une Relation Apaisé et Harmonieuse
									</h2>
									<p>
										Dans un monde où tensions et malentendus
										parasitent nos échanges, savoir écouter
										et s’exprimer sans blesser et sans être
										blessé devient essentiel. Grâce à sa
										méthode unique, Christophe Soukiassian
										vous apprend à transformer chaque
										conversation en un véritable pont vers
										des relations plus profondes,
										authentiques et apaisées.
									</p>
									<div>
										<h3>
											Un Savoir-Être et un Savoir-Faire
											Issus de l’Expérience
										</h3>
										<p>
											Depuis 2006, j’explore et affine
											cette approche dans mes
											interactions, en France et à
											l’international, auprès de personnes
											aux cultures, langues et
											sensibilités variées. La méthode
											E.E.N.B. est née d’une quête de
											bienveillance dans les relations
											conjugale, familiale, sociale et
											professionnelle : comprendre ce qui,
											dans nos façons de communiquer,
											blesse ou apaise, éloigne ou
											rapproche.
										</p>
										<ul>
											<li>
												Mon savoir-être : une présence
												ancrée et bienveillante, une
												écoute et expression fine qui
												permet de déceler les non-dits
												et d’accueillir chaque échange
												avec clarté et ouverture.
											</li>
											<li>
												Mon savoir-faire : une méthode
												éprouvée pour transformer la
												communication en un outil
												d’harmonie, en développant des
												réflexes relationnels
												bienveillants.
											</li>
										</ul>
									</div>
								</div>
								<div>
									<h2>
										Pourquoi la méthode E.E.N.B. est-elle
										différente des autres méthode de
										communication ?
									</h2>
									<p>Cette approche vous permet de :</p>
									<ul>
										<li>
											Écouter avec un calme intérieur, en
											accueillant les pensées
											émotionnelles.
										</li>
										<li>
											Exprimer avec bienveillance, sans
											crainte des réactions des autres.
										</li>
										<li>
											Désamorcer tensions et conflits,
											pour instaurer un climat relationnel
											harmonieux.
										</li>
									</ul>
									<p>
										Ce qui fait la singularité de cette
										méthode, c’est son alliance entre écoute
										intuitive, expression sincère et
										mouvement corporel libérateur. Il ne
										s’agit pas seulement d’apprendre à gérer
										les conflits inconscients, mais
										d’adopter une posture relationnelle qui
										favorise des échanges authentiques,
										fluides et bienveillants.
									</p>
								</div>
								<div>
									<h2>Une Méthode Innovante et Concrète</h2>
									<p>
										Contrairement aux approches classiques
										de la communication bienveillante, la
										méthode E.E.N.B. ne se limite pas à des
										principes théoriques. Elle propose des
										outils de pratiques en communication
										protectrice, immédiatement applicables
										pour :
									</p>
									<ul>
										<li>
											Identifier et désamorcer en
											conscience tous les conflits
											relationnels.
										</li>
										<li>
											Améliorer la qualité de vos
											relations, qu’elles soient
											personnelles ou professionnelles.
										</li>
										<li>
											Développer une présence affirmée et
											sereine, pour des échanges naturels
											constructifs.
										</li>
									</ul>
								</div>
								<div>
									<h2>
										Prêt à Transformer Votre Communication ?
									</h2>
									<p>
										Et si, en seulement trois jours, vous
										pouviez changer votre façon de
										communiquer en prenant en compte les
										réactions inconscientes ? 🌿
										<br />
										<br />
										👉 Restez connecté ! Les prochains
										articles du blog vous guideront pas à
										pas dans cette approche révolutionnaire
										libérant le stress et les pressions par
										un langage affirmatif sans reproches,
										sans jugement.
									</p>
								</div>
							</section>
						</>
					)}

					<Footer />
				</div>
			)}
		</>
	)
}

Header.propTypes = {
	isIdBlog: PropTypes.bool,
}

Header.defaultProps = {
	isIdBlog: false,
}

export default Blog
