import PropTypes from 'prop-types'

import './index.scss'

function EtiquetteList({ children }) {
	return (
		<div className="SOUKIASSIAN-EtiquetteList col-max-blocked center">
			{children}
		</div>
	)
}

EtiquetteList.propTypes = {
	children: PropTypes.any,
}

export default EtiquetteList
