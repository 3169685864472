import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './index.scss'

function Button({
	children,
	link,
	target,
	state,
	className,
	onClick,
	isDisabled,
	id,
}) {
	const classIsNotLink = link ? '' : ' SOUKIASSIAN-Button-NotLink'

	const onClickButton = classIsNotLink ? onClick : null
	const onClickLink = classIsNotLink ? null : onClick

	return (
		<button
			className={`SOUKIASSIAN-Button ${className}${classIsNotLink}`}
			tabIndex={link ? '-1' : null}
			onClick={onClickButton}
			id={id}
			disabled={isDisabled}
		>
			{link && (
				<Link
					to={link}
					target={target}
					onClick={onClickLink}
					state={state}
				>
					{children}
				</Link>
			)}
			{!link && children}
		</button>
	)
}

Button.propTypes = {
	children: PropTypes.any.isRequired,
	link: PropTypes.string,
	target: PropTypes.string,
	state: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	id: PropTypes.string,
}

Button.defaultProps = {
	className: '',
	isDisabled: false,
}

export default Button
