import './index.scss'

function LineDividing() {
	return (
		<div className="SOUKIASSIAN-LineDividing col-xl-max-blocked center">
			<hr />
		</div>
	)
}

export default LineDividing
