// import { useSelector } from 'react-redux'

import HarmoniousTitle from '../../components/HarmoniousTitle'
import PictureTextLink from '../../components/PictureTextLink'

import './index.scss'

function RegistrationCategoryList() {
	// const isConnectedUser = useSelector(
	// 	(state) => state.userReducer.isConnected,
	// )
	const pageId = 29

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="ÉVÉNEMENTS"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="event/webinars"
						pageId={pageId}
						entityId={1}
						title="WEBINAIRE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle className="col-xl-max-blocked" title="FORMATIONS">
				<div
					className="SOUKIASSIAN-RegistrationList-Category-pictureList"
					style={{ paddingTop: '30px' }}
				>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="training/training-life-coaching-first-module"
						pageId={pageId}
						entityId={7}
						promo={50}
						title="COMMUNICATION NON BLESSANTE E.E.N.B. ©"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="training/training-life-coaching"
						pageId={pageId}
						entityId={8}
						title="COMMUNICATION CONSCIENTE L.C.C. ©"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="training/training-become-coach"
						pageId={pageId}
						entityId={9}
						title={
							<>
								COACHING PLEINE
								<br />
								CONSCIENCE L.C.C. ©
							</>
						}
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="STAGES"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/consciousness-initiation"
						pageId={pageId}
						entityId={2}
						title="INITIATION CONSCIENCE"
					/>

					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/sagacity"
						pageId={pageId}
						entityId={3}
						title="SAGESSE"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/meditation"
						pageId={pageId}
						entityId={4}
						title="MÉDITATION"
						flipped
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/foreign-trip"
						pageId={pageId}
						entityId={5}
						title="ÉLÉVATION CONSCIENCE"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/eat-well"
						pageId={pageId}
						entityId={6}
						title="NOURRIR SA VIE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="AUTRES FORMATIONS"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="training/trainer-trainings"
						pageId={pageId}
						entityId={10}
						title="DEVENEZ FORMATEUR"
					/>
				</div>
			</HarmoniousTitle>
		</div>
	)
}

export default RegistrationCategoryList
