import {
	GET_BLOG,
	GET_BLOG_FILTERS,
	GET_BLOGS,
	UPDATE_TEXT_FROM_BLOG,
} from '../actions/blog.action'

const initialState = { list: [], listFilter: [] }

export default function blogReducer(state = initialState, action) {
	let blogs = null
	let blog = null
	let blogId = null
	let filters = null

	switch (action.type) {
		case GET_BLOG:
			blog = action.payload
			blog.image = JSON.parse(blog.image)
			blog.texts = JSON.parse(blog.texts)
			blogId = blog.id
			return {
				...state,
				list: updateById(state.list, blogId, blog),
			}
		case GET_BLOGS:
			blogs = action.payload
			return {
				...state,
				list: [
					...blogs.map((blog) => {
						blog.image = JSON.parse(blog.image)
						return blog
					}),
				],
			}
		case GET_BLOG_FILTERS:
			filters = action.payload
			return {
				...state,
				listFilter: [...filters],
			}
		case UPDATE_TEXT_FROM_BLOG:
			blogId = action.payload.blogId
			blog = state.list[blogId]
			blog['texts'][action.payload.textId] = action.payload.text
			return {
				...state,
				list: updateById(state.list, blogId, blog),
			}
		default:
			return { ...state }
	}
}

function updateById(blogList, updatedBlog) {
	return blogList.map((blog) => {
		if (updatedBlog.id === blog.id) {
			return { ...updatedBlog }
		} else {
			return blog
		}
	})
}
