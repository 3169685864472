import PropTypes from 'prop-types'

import Img from '../Img'

import './index.scss'
import { Link } from 'react-router-dom'

function BlogEtiquette({
	title,
	subTitle,
	description,
	pageId,
	entityId,
	blogId,
}) {
	return (
		<div className="SOUKIASSIAN-BlogEtiquette-container">
			<div className="SOUKIASSIAN-BlogEtiquette-background">
				<Link className="SOUKIASSIAN-BlogEtiquette" to={`${blogId}`}>
					<div className="SOUKIASSIAN-BlogEtiquette-title">
						{title}
					</div>
					<div className="SOUKIASSIAN-BlogEtiquette-image-container">
						<Img pageId={pageId} entityId={entityId} />
						{/* <div>
							<h2 className="SOUKIASSIAN-BlogEtiquette-subTitle">
								{subTitle}
							</h2>
							<p className="SOUKIASSIAN-BlogEtiquette-description">
								{description}
							</p>
						</div> */}
					</div>
				</Link>
			</div>
		</div>
	)
}

BlogEtiquette.propTypes = {
	title: PropTypes.any,
	subTitle: PropTypes.any,
	description: PropTypes.any,
	pageId: PropTypes.number,
	entityId: PropTypes.number,
	blogId: PropTypes.number,
}

export default BlogEtiquette
