import { initPage } from '../../utils'

const registrationListLoader = ({ params }) => {
	const id = params.id || 0

	const categoryToTitle = {
		event: {
			webinars: {
				titleHtml: 'événement webinaire',
				registrationId: id,
			},
			tradeshows: {
				titleHtml: 'événement salon',
				registrationId: id,
			},
			conferences: {
				titleHtml: 'événement conférence',
				registrationId: id,
			},
			workshops: {
				titleHtml: 'événement atelier',
				registrationId: id,
			},
		},
		stage: {
			'consciousness-initiation': {
				titleHtml: 'stage initation conscience',
				registrationId: id,
			},
			sagacity: {
				titleHtml: 'stage sagesse',
				registrationId: id,
			},
			meditation: {
				titleHtml: 'stage méditation',
				registrationId: id,
			},
			'eat-well': {
				titleHtml: 'stage bien se nourrir',
				registrationId: id,
			},
			'foreign-trip': {
				titleHtml: "stage séjour à l'étranger",
				registrationId: id,
			},
		},
		training: {
			'training-life-coaching-first-module': {
				titleHtml: "formation d'écoute et d'expression non blessante",
				registrationId: id,
			},
			'training-life-coaching': {
				titleHtml: 'formation coacher sa vie',
				registrationId: id,
			},
			'training-become-coach': {
				titleHtml: 'formation devenir coach',
				registrationId: id,
			},
			'trainer-trainings': {
				titleHtml: 'formation de formateur',
				registrationId: id,
			},
		},
	}

	const categoryExist = Object.hasOwnProperty.call(
		categoryToTitle,
		params.category,
	)

	if (!categoryExist) {
		throw new Response('Not Found', { status: 404 })
	} else {
		const subcategoryExist = Object.hasOwnProperty.call(
			categoryToTitle[params.category],
			params.subcategory,
		)

		if (!subcategoryExist) throw new Response('Not Found', { status: 404 })
		else {
			initPage(
				'Inscription ' +
					categoryToTitle[params.category][params.subcategory]
						.titleHtml,
			)

			categoryToTitle[params.category][params.subcategory].category =
				params.category
			categoryToTitle[params.category][params.subcategory].subcategory =
				params.subcategory

			return categoryToTitle[params.category][params.subcategory]
		}
	}
}

export default registrationListLoader
