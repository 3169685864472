import PropTypes from 'prop-types'

import './index.scss'

function BlogEtiquetteList({ children }) {
	return (
		<div className="SOUKIASSIAN-BlogEtiquetteList col-xl-max-blocked center">
			{children}
		</div>
	)
}

BlogEtiquetteList.propTypes = {
	children: PropTypes.any,
}

export default BlogEtiquetteList
